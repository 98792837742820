import { Container, Header, ContainerContent, Title, ContainerData, CountryLine, DataCountry, TitleCountry,Footer, TextFooter } from './styled';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { countriesWithDeliverySelector, countryCode, countrySelection } from 'services/helpers';

const CountrySelector = () => {
    const router = useRouter();
    return(
        <Container>
            <ContainerData>
                <Header>
                    <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
                </Header>
                <ContainerContent>
                    <Title>
                        <FormattedMessage
                            id='titleSelector'
                            defaultMessage='Choose your country'
                        />
                    </Title>
                    {
                        countriesWithDeliverySelector.map((country, index) => {
                            return(
                                <CountryLine key={index} country={countryCode[country.toString()]} onClick={() => router.push(`/${countrySelection[country.toString()].route}`)}>
                                    {
                                        countrySelection[country.toString()] &&
                                        <>
                                            <DataCountry>
                                                <Image src={countrySelection[country.toString()].flag} width={32} height={32} alt='country' />
                                                <TitleCountry>
                                                    {countrySelection[country.toString()].name}
                                                </TitleCountry>
                                            </DataCountry>
                                            <Image src={'/imgs/right.png'} width={24} height={24} alt='country' />
                                        </>
                                    }
                                </CountryLine>
                            )
                        }
                        )
                    }
                    
                </ContainerContent>
            </ContainerData>
            <Footer>
                <div>
                      <Image src='/imgs/logoComplete.svg' width={148} height={48} alt='logo' />
                </div>
                <div style={{display: 'flex', width: '100%'}}>
                    <div style={{display: 'flex', alignItems: 'center', cursor:'pointer'}} onClick={()=> window.open("https://www.facebook.com/profile.php?id=100080200750387", "facebook.com")}>
                        <Image src='/imgs/facebookIcon.svg' width={16} height={16} alt='facebookIcon' />
                        <TextFooter style={{margin: '0px 0px 0px 4px'}}>Facebook</TextFooter>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginLeft:'24px', cursor:'pointer'}} onClick={()=> window.open("https://www.instagram.com/mercaditomexicoof/", "instagram.com")}>
                        <Image src='/imgs/instagramIcon.svg' width={16} height={16} alt='instagramIcon' />
                        <TextFooter style={{margin: '0px 0px 0px 4px'}}>Instagram</TextFooter>
                    </div>
                </div>
            </Footer>
        </Container>
    )
}

export default CountrySelector;